.MuiBox-root.css-1duothe {
    width: 570px !important;
}

.swal-text{
    text-align: center !important;
}
.swal-title{
    text-transform: capitalize;
}

@media screen and (max-width: 600px) {
    .MuiBox-root.css-1duothe {
        width: 420px !important;
    }

    .css-16ut1rm,
    .css-7p547w,
    .css-18y2uza-MuiTypography-root-MuiTimelineContent-root,
    .css-n2itgq-MuiTypography-root-MuiTimelineOppositeContent-root {
        font-size: 12px !important;
    }


}

@media screen and (max-width: 450px) {
    .MuiBox-root.css-1duothe {
        width: 320px !important;
    }

    .css-16ut1rm,
    .css-7p547w,
    .css-18y2uza-MuiTypography-root-MuiTimelineContent-root,
    .css-n2itgq-MuiTypography-root-MuiTimelineOppositeContent-root {
        font-size: 10px !important;
    }

}

@media screen and (max-width: 300px) {
    .MuiBox-root.css-1duothe {
        width: 270px !important;
    }

    .css-16ut1rm,
    .css-7p547w,
    .css-18y2uza-MuiTypography-root-MuiTimelineContent-root,
    .css-n2itgq-MuiTypography-root-MuiTimelineOppositeContent-root {
        font-size: 10px !important;
    }
}


/* Additional css */

table thead tr {
    background: #002626;
    color: antiquewhite;
}

tr.table-sm {
    background-color: transparent !important;
}

.c-header .c-header-toggler-icon {
    background-image: url('../assets/menu-burger.svg') !important;
}

.c-sidebar-nav-item,
.c-sidebar-nav-dropdown {
    padding: 5px 15px;
}
/* .c-sidebar-minimized .c-sidebar-nav .c-sidebar-nav-item,
.c-sidebar-minimized .c-sidebar-nav .c-sidebar-nav-dropdown {
    padding: 0px 0px;
} */

.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle,.page-item.active .page-link {
    background: #002626 !important;
}
.page-item.active .page-link {
    background: #002626 !important;
    border:1px solid #002626 !important;
    color: #40B097 !important;
}
/* .react-switch-bg{
    background: #002626 !important;
} */
.c-active,.page-link{
    color: #40B097 !important;
}
.pagination{
    margin-top: 25px;
}
.card-header{
    font-size: 16px;
    font-weight: 600;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show,
.c-sidebar-nav-dropdown-items .c-sidebar-nav-item .c-sidebar-nav-link {
    background: none !important;
}

.c-sidebar-nav-dropdown-items .c-sidebar-nav-item .c-sidebar-nav-link {
    padding-left: 40px !important;
}

.c-sidebar-nav-title {
    border: 2px solid linear-gradient(120deg, #465D58, #428F7E);
    /* border-image: linear-gradient(120deg, #465D58,#428F7E); */
}

.c-sidebar-nav-dropdown-items {
    background-image: linear-gradient(to bottom right, rgba(62, 174, 151, 0.2), rgba(62, 174, 151, 0.7));
}

.c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items .c-sidebar-nav-item {
    color: whitesmoke !important;
}
.c-sidebar-nav-item .c-sidebar-nav-link svg, .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle svg{
    color: whitesmoke !important;
}
.c-sidebar-nav-title {
    position: relative;
    width: 88% !important;
    margin: 5px auto !important;
    padding: 0.75rem 0rem !important;   
}
.c-sidebar-nav-title::before {
    content: '';
    position: absolute;
    margin-top: 2px;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px; /* Height of the gradient border */
    /* background: linear-gradient(to bottom right, rgba(62, 174, 151, 0.2), rgba(62, 174, 151, 0.7)) */
    background-image: radial-gradient(rgba(62,174,151,1), rgba(62,174,151,0));
}
.btn-green{
    color: #fff;
    background-color: #002626 !important;
    border-color: #40B097 !important;
}
.btn-green:hover{
    color: #fff;
    border-color: #40B097 !important;
}
.breadcrumb-item.active{
    color: #40B097 !important;
    font-weight:500;
}
.breadcrumb-item a, .breadcrumb-item + .breadcrumb-item::before{
    color: #002626;
    font-weight:500;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle::after{
    /* display: none; */
    background-image: url("../assets/left-Arrow.svg");
}
.c-sidebar-nav-dropdown-toggle::after{
    /* height: 30px !important; */
    flex: 0 1 20px;
}
.c-sidebar :hover.c-sidebar-nav-dropdown-toggle::after{
    background-image: url("../assets/left-Arrow.svg");

}
.c-sidebar .c-sidebar-brand{
    background: none !important;
}
.c-sidebar-brand.d-md-down-none.active{
    padding: 25px 0px !important;
}
/* .ps__rail-y{
    display: none !important;
} */
.c-sidebar-minimized .c-sidebar-minimizer.c-d-md-down-none{
    margin-left: -15px;
}
.btn-notactive{
    border-radius: 0px !important;
    background: #40B097 !important;
    color: white !important;
}
@media screen and (max-width: 360px) {
    .d-flex.justify-content-between span{
        font-size: 10px;
    }
    .col-12.card.mt-3.py-2{
        padding: 0px;
        /* margin: 0px; */

    }
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items {
    /* margin-left: -41px; */
    z-index: 2678323457732345678;
}
.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover {
    width: 282px;
}
.c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-link{
    width: 211px;
}

@media screen and (min-width: 992px) {

    .c-sidebar-minimized.c-sidebar-fixed {
        z-index: 1031;
        width: 96px;
    }
    .c-sidebar-minimized .c-sidebar-nav-title{
        opacity: 1;
        visibility: visible;
    }
    .c-sidebar-minimized.c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right){
        padding-left: 15px;
    }
    .c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
        background: none !important;
    }
    html:not([dir=rtl]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper{
        margin-left: 96px;
    }
    .c-sidebar.c-sidebar-dark.c-sidebar-fixed html:not([dir=rtl]) .c-sidebar.c-sidebar-fixed:not(.c-sidebar-right) {
        left: -44px;
    }
    html:not([dir=rtl]) .c-sidebar.c-sidebar-fixed:not(.c-sidebar-right) {
        left: -44px;
    }
    html:not([dir=rtl]) .c-sidebar.c-sidebar-lg-show.c-sidebar-fixed:not(.c-sidebar-right) {
        left: 0 !important;
    }
}
